import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {PacienteService} from '../paciente.service';
import {PatientView} from '../paciente.classes';
import {Subscription} from "rxjs/Subscription";
import { CatalogosService } from 'app/shared/services/catalogos.service';
import {environment} from "environments/environment";
import { NivelesCovid, NivelesGlu, NivelesPA, NivelesPeso } from 'app/shared/classes/common.classes';
import { log } from 'console';
import { NotificationService } from 'app/shared/utils/notification.service';


@Component({
    selector: 'app-paciente-ficha-layout',
    templateUrl: './paciente.ficha.layout.html',
    styleUrls: ['./paciente.ficha.layout.css']
})
export class PacienteFichaLayout implements OnInit, OnDestroy {
    @Input() paciente: PatientView | string;
    // En donde se requiera nowPaciente voy a emplear patient details.

    patientDetails: PatientView = new PatientView();
    patientAge: number;
    pacti :PatientView = new PatientView();
    tutor:boolean;
    subscriptions: Subscription = new Subscription();
    nombr:string;
    telef:string;
    email:string;
    paren:string;
    ServGLU:boolean= false;
    ServPA:boolean= false;
    ServPE:boolean= true;
    ServCOV:boolean= false;
    isAlentha:boolean=false;
    _idgroups : string ="";
    NivPeso: NivelesPeso= new NivelesPeso();  
    NivGlu: NivelesGlu= new NivelesGlu();
    NivCovid: NivelesCovid= new NivelesCovid();
    NivPA: NivelesPA = new NivelesPA();
    gen1;
    gen2;
    gen3;

    constructor(private catalogosService: CatalogosService,private pacienteService: PacienteService,private notificationService:NotificationService) {
    }

    ngOnInit() {
        // Patientview o IdPaciente
        if (this.paciente) {
            if (this.paciente instanceof PatientView) {
                    this.patientDetails = this.paciente;
                    this.cargarDatosPaciente();
            } else {
                
                    this.patientDetails.idPaciente = this.paciente;
                    this.cargarDatosPaciente();
            }
        }
        var seletedgroupd = localStorage.getItem('id_select_group');
        if(environment.Instance_file=='ECE'){
            this._idgroups='24';
        }else{
            this._idgroups='14';
        }
        if(seletedgroupd == this._idgroups){
            this.isAlentha=true;
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }


    SendNiveles(TIPO){
        const dateTime = Date.now();
        if(TIPO=="GLUCOSA"){
            this.NivGlu = new NivelesGlu();
            this.NivGlu.glumedida=this.gen1;
            this.NivGlu.gluperiodo=9;
            this.NivGlu.pacidfk = this.patientDetails.idPaciente;
            this.NivGlu.medidfk="";
            this.NivGlu.glufechahora=dateTime.toString();
            this.pacienteService.setNIVGLU(this.NivGlu)
            .subscribe(
                (rest) => {
                   if(rest){
                    this.notificationService.smallBox({
                                title: 'NIVELES ENVIADOS CON EXITO',
                                content: '<br>',
                                color: "#739E73",
                                icon: "fa fa-check",
                                timeout: 3000
                            });
                            this.pacienteService.getNIVGLU(this.patientDetails.idPaciente)
                            .subscribe(
                                (NivGLU) => {
                                    
                                    var sds ="";
                                   //this.barras.Upload(10,this.TIPO,this.Glu);
                                },
                                () => {
                                    var sds ="";
                                   
                                }
                            ); 
                   }
                }, error => {
                    this.notificationService.smallBox({
                        title: 'ERROR',
                        content: 'ERROR AL INGRESAR NIVELES' +error.message.toString(),
                        color: '#ffc107',
                        icon: 'fa fa-warning shake',
                        timeout: 3000
                    });
                  
                }
            ); 

            
        }else if (TIPO=="PRESION ARTERIAL"){
            this.NivPA = new NivelesPA();
            this.NivPA.pasysmedida=this.gen1;
            this.NivPA.padiamedida=this.gen2;
            this.NivPA.pafcmedida=this.gen3;
            this.NivPA.paperiodo=9;
            this.NivPA.pacidfk = this.patientDetails.idPaciente;
            this.NivPA.medidfk="";
            this.NivPA.pafechahora=dateTime.toString();
       this.pacienteService.setNIVPA(this.NivPA)
            .subscribe(
                (rest) => {
                   if(rest){
                    this.notificationService.smallBox({
                                title: 'NIVELES ENVIADOS CON EXITO',
                                content: '<br>',
                                color: "#739E73",
                                icon: "fa fa-check",
                                timeout: 3000
                            });
                            this.pacienteService.getNIVPA(this.patientDetails.idPaciente)
                            .subscribe(
                                (NivPA) => {
                                    var sds ="";
                                },
                                () => {
                                    var sds ="";
                                   
                                }
                            );     
                   }
                }, error => {
                    this.notificationService.smallBox({
                        title: 'ERROR',
                        content: 'ERROR AL INGRESAR NIVELES' +error.message.toString(),
                        color: '#ffc107',
                        icon: 'fa fa-warning shake',
                        timeout: 3000
                    });
                  
                }
            ); 

        }else if (TIPO=="PESO"){
            this.NivPeso = new NivelesPeso();
            this.NivPeso.pesomedida=this.gen1;
            this.NivPeso.pesoperiodo=9;
            this.NivPeso.pacidfk = this.patientDetails.idPaciente;
            this.NivPeso.medidfk="";
            this.NivPeso.pesofechahora=dateTime.toString();
            this.pacienteService.setNIVPESO(this.NivPeso)
            .subscribe(
                (rest) => {
                   if(rest){
                    this.notificationService.smallBox({
                                title: 'NIVELES ENVIADOS CON EXITO',
                                content: '<br>',
                                color: "#739E73",
                                icon: "fa fa-check",
                                timeout: 3000
                            });
                            this.pacienteService.getNIVPESO(this.patientDetails.idPaciente)
                            .subscribe(
                                (NivPESO) => {
                                    var sds ="";
                                   
                                },
                                () => {
                                    var sds ="";
                                   
                                }
                            );    
                   }
                }, error => {
                    this.notificationService.smallBox({
                        title: 'ERROR',
                        content: 'ERROR AL INGRESAR NIVELES' +error.message.toString(),
                        color: '#ffc107',
                        icon: 'fa fa-warning shake',
                        timeout: 3000
                    });
                  
                }
            ); 
        }else if (TIPO=="SIGNOS VITALES"){
            this.NivCovid = new NivelesCovid();
            this.NivCovid.covidspomedida=this.gen1;
            this.NivCovid.covidpulsomedida=this.gen2;
            this.NivCovid.covidtempmedida=this.gen3;
            this.NivCovid.covidperiodo=9;
            this.NivCovid.pacidfk = this.patientDetails.idPaciente;
            this.NivCovid.medidfk="";
            this.NivCovid.covidfechahora=dateTime.toString();
            this.pacienteService.setNIVCOVID(this.NivCovid)
            .subscribe(
                (rest) => {
                   if(rest){
                    this.notificationService.smallBox({
                                title: 'NIVELES ENVIADOS CON EXITO',
                                content: '<br>',
                                color: "#739E73",
                                icon: "fa fa-check",
                                timeout: 3000
                            });
                           
                            this.pacienteService.getNIVCOVID(this.patientDetails.idPaciente)
                            .subscribe(
                                (NivCOVID) => {
                                    var sds ="";
                                },
                                () => {
                                    var sds ="";
                                   
                                }
                            ); 
                            
                   }
                }, error => {
                    this.notificationService.smallBox({
                        title: 'ERROR',
                        content: 'ERROR AL INGRESAR NIVELES' +error.message.toString(),
                        color: '#ffc107',
                        icon: 'fa fa-warning shake',
                        timeout: 3000
                    });
                  
                }
            ); 
        }

        this.gen1=0;
        this.gen2=0;
        this.gen3=0;
        document.getElementById("cancelarnivAll").click();

    }

    /**
     * Cargar los datos del paciente del REST paciente, si ya tiene el nombre quiere decir que tiene los demás datos
     * (incluidos datos clínicos), ya no o pidas por HTTP
     */
    cargarDatosPaciente() {
        if (!this.patientDetails.nombre && this.patientDetails.idPaciente) {
            this.subscriptions.add(
                this.pacienteService.getById(this.patientDetails.idPaciente)
                    .subscribe(patientView => {
                           if(patientView.catServiciosList){
                            patientView.catServiciosList.forEach(element => {
                                    switch(element.idCatServicio){
                                        case 1:
                                            this.ServCOV = true;
                                        break;
                                        case 2:
                                            this.ServGLU = true;
                                        break;
                                        case 3:
                                            this.ServPE = true;
                                        break;
                                        case 4:
                                            this.ServPA = true;
                                        break;
                                    }

                                });
                            }
                            this.tutor = patientView.es_titular;
                        if (!patientView.idPaciente) return;

                        if (patientView.idPaciente == this.patientDetails.idPaciente) {
                            this.patientDetails = patientView;
                            // if (this.patientDetails.fechaNacimiento.valueOf() < 0) { // less than 1970
                            //     let newFechaNacimiento: string = this.patientDetails.fechaNacimiento.valueOf().toString();
                            //     while (newFechaNacimiento.length < 13) { // 13 for milliseconds (with negative symbol -)
                            //         newFechaNacimiento += "0";
                            //     }
                            //     this.patientDetails.fechaNacimiento = new Date(parseInt(newFechaNacimiento));
                            // }
                            this.patientAge = Math.abs(new Date(Date.now() - new Date(this.patientDetails.fechaNacimiento).getTime()).getUTCFullYear() - 1970);
                            // @ts-ignore
                            this.pacienteService.pacienteActual = this.patientDetails;
                            if(!this.tutor){
                                this.catalogosService.GETRELtutores(patientView.idPaciente).subscribe(rt=>{
                                    console.log("dentro del ficha layout rt");
                                    console.log(rt);
                                    console.log("FUERA del ficha layout rt");

                                    this.nombr=rt.nombreTutor;
                                    this.telef=rt.telefono;
                                    this.email=rt.email;
                                    this.paren=rt.parentesco;
                                });
                            }

                        }
                    })
            );
        } else {
            this.patientAge = new Date(Date.now() - new Date(this.patientDetails.fechaNacimiento).getTime()).getFullYear();
        }
    }
}
