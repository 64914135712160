import {Component, ElementRef, Input, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import {AuthNioService} from '../../../+login-nio/auth-nio.service';
import {OptionsInput} from '@fullcalendar/core/types/input-types';
import {DatepickerRegional, Evento, FullcalendarViewTypes} from '../../../+agenda/evento.classes';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import {FullCalendarComponent} from '@fullcalendar/angular';
import {Calendar} from '@fullcalendar/core';
import {I18nService} from '../../../shared/i18n';
import {EventApi} from '@fullcalendar/core/api/EventApi';
import {isDate} from 'ngx-bootstrap/chronos';
import {NotificationService} from '../../../shared/utils/notification.service';
import {Agendamedicos, MedicoView} from '../../../+doctor/doctor.classes';
import {NiomedicPageRequest} from '../../../common/http/niomedic.page.request';
import {EventosService} from '../../../+agenda/eventos.service';
import {PageImpl} from '../../../common/http/PageImpl';
import {DoctorService} from '../../../+doctor/doctor.service';
import {EventInput} from "@fullcalendar/core/structs/event";
import EventSourceApi from "@fullcalendar/core/api/EventSourceApi";
import {environment} from '../../../../environments/environment';
import { ComunService } from 'app/shared/services/comun.service';
import { PageRequest } from 'app/common/http/page.request';
import * as moment from 'moment';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-disponibilidad',
    templateUrl: './disponibilidad.component.html',
    styleUrls: ['./disponibilidad.component.css']
})
export class DisponibilidadComponent implements OnInit {
    @Input() private availabilityModalStyle: CSSStyleDeclaration = document.createElement('span').style;
    @Input() idModal: string = "modalDisponibilidad";
    @Input() medicoSeleccionado: MedicoView = new MedicoView();
    @Input() cssExtra: boolean;
    @Input() private loadData: EventEmitter<void>;
    @Output() private close: EventEmitter<any> = new EventEmitter();
    Daysout = "";
    StarTime ="00:00:01";
    EndTime ="23:59:59";
    idMed ="";
    model: NgbDateStruct;
    date: {year: number, month: number};
    pageSearch: PageRequest = new PageRequest();
    catEspecialidades: { id: string, nombre: string }[];
    paginadoMedico: PageImpl<MedicoView> = new PageImpl();
    Agenda: Agendamedicos = new Agendamedicos();
    selected: Date | null;
    @ViewChild('calendarDis') private calendarComponent: FullCalendarComponent;
    private _calendar: Calendar;
    FERCHA :any;
    VISTAAGENDA = true;
    calendarConfig: { ready: boolean, options: OptionsInput, dialog: object } = {
        ready: false,
        options: {
            height: 'auto',
            defaultView: FullcalendarViewTypes.MONTH,
            plugins: [interactionPlugin, timeGridPlugin],
            slotLabelFormat: {
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: true,
                hour12: true
            },
            eventTimeFormat: {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            },
            locale: 'es',
            header: false,
            defaultTimedEventDuration: {
                minutes: 30
            }
        },
        dialog: {
            autoOpen: false,
            closeText: '',
            draggable: false,
            resizable: false,
            classes: {
                'ui-dialog': 'app-agenda'
            }
        }
    };

    @ViewChild('datepicker') private datepickerEl: ElementRef;
    private datepickerRegional: DatepickerRegional = new DatepickerRegional();
    datepickerConfig = {
        clicked: true,
        timeout: 0,
        options: {
            prevText: '<i class="fa fa-angle-left fa-lg fontw-600"></i>',
            nextText: '<i class="fa fa-angle-right fa-lg fontw-600"></i>'
        },
        callbacks: [(selectedDate) => this.datepickerDateSelected(selectedDate)]
    };

    @ViewChild('modalAvailability') private modalAvailabilityEl: ElementRef;

    private contadorIntentos: number = 0;
    mappedModalStyle: { [property: string]: string } = {};
    styles:boolean;
    private editingEvent: boolean = false;
    private allDayEvent: boolean = false;
    private modelAddEvent: Evento = new Evento();
    private EventoManual: Evento = new Evento();
    private addedEvent: EventApi;
    private previouslySaved: {event: EventApi, reopen: boolean} = {
        event: null,
        reopen: false
    };
    private ismedic:boolean =false;
    private todayZeroTime: Date = new Date();
    private filtersRequestEvents: NiomedicPageRequest = new NiomedicPageRequest();
    private agendaEvents: PageImpl<Evento>;
    medicoSelecteds:any;
    rangosHorasM:any[] = [];
    rangosHorasV:any[] = [];
    rangosHorasN:any[] = [];
    rangosHoras: any[] = [];
    safeUrl: SafeResourceUrl;
    constructor(public authservice: AuthNioService,
                private notificationService: NotificationService,
                private comunService: ComunService,
                private eventosService: EventosService,
                private sanitizer: DomSanitizer,
                private i18nService: I18nService,
                private doctorService: DoctorService) {
        this.calendarConfig.options.locale = this.i18nService.currentLanguage.key;
        Object.assign(this.datepickerConfig.options, this.datepickerRegional.languages[this.i18nService.currentLanguage.key]);
        if(environment.navigate=="NA" || this.authservice.token.idTipoUsuario != 2){ // Si es NA o no es medico (admin no es medico)
            this.styles = false;
        } else{
            this.styles =true;
        }

        this.pageSearch.page = 0;
        this.pageSearch.size = 9999;
        this.pageSearch.orderColumn = 'nombre';
        this.pageSearch.orderType = 'desc';
        this.pageSearch.selectGroup=0;
        this.paginadoMedico.numberOfElements = this.paginadoMedico.totalElements = 0;
        this.paginadoMedico.first = this.paginadoMedico.last = true;
    }

    ngOnInit() {


        var USERNAME =this.authservice.token.username;
        if(USERNAME !='DRCAMPA' && USERNAME !='MARISOL' && USERNAME !='CMARQUINEZ'){
            USERNAME ='DRBRAVO'
        }
        //const url = 'https://calendar.google.com/calendar/embed?src=telemedicinamx1%40gmail.com&ctz=America%2FMexico_City';
       var url = 'https://scheduler.zoom.us/medios-consulta-en-linea/'+USERNAME+'?embed=true';

      // console.log(url);

        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);


        this.rangosHorasM=[];
        this.rangosHorasV=[];
        this.rangosHorasN=[];
        this.calendarConfig.ready = true;
        this.todayZeroTime.setHours(0, 0, 0, 0);
        this.loadData.subscribe(() => {
            this.previouslySaved.reopen = !!this.addedEvent;
            if (this.addedEvent) {
                if (this.calendar) {
                    setTimeout(() => this.calendar.rerenderEvents(), 200);
                }
            } else {
                if (this.calendar) {
                    setTimeout(() => this.calendar.render(), 200);
                }
            }
        });

       if(environment.navigate=='NA' || this.authservice.token.idTipoUsuario != 2){ // Si es NA o no es medico (admin no es medico)
            if (this.availabilityModalStyle.cssText) {
                this.availabilityModalStyle.cssText.split(";")
                    .forEach((propertyValue) => {
                        if (propertyValue) {
                            let arrayPropValue: string[] = propertyValue.split(":", 2);
                            this.mappedModalStyle[arrayPropValue[0].trim()] = arrayPropValue[1].trim();
                        }
                    });
            }
            this.comunService.getCatEspecialidades().subscribe(data => this.catEspecialidades = data);
        }
        
        //this.onSelectMEDICO();
        
    }

    private datepickerDateSelected(selectedDate): void {
        console.log(selectedDate);
        this.FERCHA = selectedDate;
        this.datepickerConfig.clicked = true;
        console.log(this.datepickerConfig);
        clearTimeout(this.datepickerConfig.timeout);
        this.datepickerConfig.timeout = window.setTimeout(() => this.datepickerConfig.clicked = true, 500);

      //  this.calendar.gotoDate(new Date(selectedDate));

        // INICIA EVENTO DE LA AGENDA Y HORARIOS
        console.log("INICIO AGENDA");
        console.log(this.Agenda);
        var day  = new Date(selectedDate).getDay();
        console.log(day);

        
        
            if(this.authservice.token.idTipoUsuario==2 && this.Agenda.sabEntMat===undefined){
                this.doctorService.getByIdUser(this.authservice.token.userId)
            .subscribe(
                (doctor) => {
                    this.doctorService.getMedAgenda(doctor.idMedico).subscribe(
                        (Agenda) => {
                         if(Agenda.content.length>0){
                             this.Agenda = new Agendamedicos();
                             this.Agenda = Agenda.content[0];
                              switch(day){
                                case 0:
                                    if(this.Agenda.meddom){
                                        this.VISTAAGENDA=true;
                                        this.SetHoursAgenda(selectedDate,this.Agenda.domEntMat,this.Agenda.domSalMat,this.Agenda.domEntVesp,this.Agenda.domSalVesp,this.Agenda.domEntNoct,this.Agenda.domSalNoct);
                                    }else{this.VISTAAGENDA=false;}
                                    break;
                                case 1:
                                    if(this.Agenda.medlun){
                                        this.VISTAAGENDA=true;
                                        this.SetHoursAgenda(selectedDate,this.Agenda.lunEntMat,this.Agenda.lunSalMat,this.Agenda.lunEntVesp,this.Agenda.lunSalVesp,this.Agenda.lunEntNoct,this.Agenda.lunSalNoct);
                                    }else{this.VISTAAGENDA=false;}
                                    break;
                                case 2:
                                    if(this.Agenda.medmar){
                                        this.VISTAAGENDA=true;
                                        this.SetHoursAgenda(selectedDate,this.Agenda.marEntMat,this.Agenda.marSalMat,this.Agenda.marEntVesp,this.Agenda.marSalVesp,this.Agenda.marEntNoct,this.Agenda.marSalNoct);
                                    }else{this.VISTAAGENDA=false;}
                                    break;
                                case 3:
                                    if(this.Agenda.medmie){
                                        this.VISTAAGENDA=true;
                                        this.SetHoursAgenda(selectedDate,this.Agenda.mieEntMat,this.Agenda.mieSalMat,this.Agenda.mieEntVesp,this.Agenda.mieSalVesp,this.Agenda.mieEntNoct,this.Agenda.mieSalNoct);
                                    }else{this.VISTAAGENDA=false;}
                                    break;
                                case 4:
                                    if(this.Agenda.medjue){
                                        this.VISTAAGENDA=true;
                                        this.SetHoursAgenda(selectedDate,this.Agenda.jueEntMat,this.Agenda.jueSalMat,this.Agenda.jueEntVesp,this.Agenda.jueSalVesp,this.Agenda.jueEntNoct,this.Agenda.jueSalNoct);
                                    }else{this.VISTAAGENDA=false;}
                                    break;
                                case 5:
                                    if(this.Agenda.medvie){
                                        this.VISTAAGENDA=true;
                                        this.SetHoursAgenda(selectedDate,this.Agenda.vieEntMat,this.Agenda.vieSalMat,this.Agenda.vieEntVesp,this.Agenda.vieSalVesp,this.Agenda.vieEntNoct,this.Agenda.vieSalNoct);
                                    }else{this.VISTAAGENDA=false;}
                                    break;
                                case 6:
                                    if(this.Agenda.medsab){
                                        this.VISTAAGENDA=true;
                                        this.SetHoursAgenda(selectedDate,this.Agenda.sabEntMat,this.Agenda.sabSalMat,this.Agenda.sabEntVesp,this.Agenda.sabSalVesp,this.Agenda.sabEntNoct,this.Agenda.sabSalNoct);
                                    }else{this.VISTAAGENDA=false;}
                                    break;
                                }
                         }
                        }
                    ); 
                });
            }else{
                    switch(day){
                        case 0:
                            if(this.Agenda.meddom){
                                this.VISTAAGENDA=true;
                                this.SetHoursAgenda(selectedDate,this.Agenda.domEntMat,this.Agenda.domSalMat,this.Agenda.domEntVesp,this.Agenda.domSalVesp,this.Agenda.domEntNoct,this.Agenda.domSalNoct);
                            }else{this.VISTAAGENDA=false;}
                            break;
                        case 1:
                            if(this.Agenda.medlun){
                                this.VISTAAGENDA=true;
                                this.SetHoursAgenda(selectedDate,this.Agenda.lunEntMat,this.Agenda.lunSalMat,this.Agenda.lunEntVesp,this.Agenda.lunSalVesp,this.Agenda.lunEntNoct,this.Agenda.lunSalNoct);
                            }else{this.VISTAAGENDA=false;}
                            break;
                        case 2:
                            if(this.Agenda.medmar){
                                this.VISTAAGENDA=true;
                                this.SetHoursAgenda(selectedDate,this.Agenda.marEntMat,this.Agenda.marSalMat,this.Agenda.marEntVesp,this.Agenda.marSalVesp,this.Agenda.marEntNoct,this.Agenda.marSalNoct);
                            }else{this.VISTAAGENDA=false;}
                            break;
                        case 3:
                            if(this.Agenda.medmie){
                                this.VISTAAGENDA=true;
                                this.SetHoursAgenda(selectedDate,this.Agenda.mieEntMat,this.Agenda.mieSalMat,this.Agenda.mieEntVesp,this.Agenda.mieSalVesp,this.Agenda.mieEntNoct,this.Agenda.mieSalNoct);
                            }else{this.VISTAAGENDA=false;}
                            break;
                        case 4:
                            if(this.Agenda.medjue){
                                this.VISTAAGENDA=true;
                                this.SetHoursAgenda(selectedDate,this.Agenda.jueEntMat,this.Agenda.jueSalMat,this.Agenda.jueEntVesp,this.Agenda.jueSalVesp,this.Agenda.jueEntNoct,this.Agenda.jueSalNoct);
                            }else{this.VISTAAGENDA=false;}
                            break;
                        case 5:
                            if(this.Agenda.medvie){
                                this.VISTAAGENDA=true;
                                this.SetHoursAgenda(selectedDate,this.Agenda.vieEntMat,this.Agenda.vieSalMat,this.Agenda.vieEntVesp,this.Agenda.vieSalVesp,this.Agenda.vieEntNoct,this.Agenda.vieSalNoct);
                            }else{this.VISTAAGENDA=false;}
                            break;
                        case 6:
                            if(this.Agenda.medsab){
                                this.VISTAAGENDA=true;
                                this.SetHoursAgenda(selectedDate,this.Agenda.sabEntMat,this.Agenda.sabSalMat,this.Agenda.sabEntVesp,this.Agenda.sabSalVesp,this.Agenda.sabEntNoct,this.Agenda.sabSalNoct);
                            }else{this.VISTAAGENDA=false;}
                            break;
                        }
                }
       

    }

    PostEvent(id,hora){
        console.log(id);
        console.log(hora);
        // console.log(document.getElementById(id));
        // console.log(document.getElementById(id).style.backgroundColor);
        // document.getElementById(id).style.backgroundColor = "#0D2D59";

       
       



        this.EventoManual = new Evento();

        this.EventoManual

        var fecs = this.FERCHA+' '+hora.hora+':00';
        var fecha = fecs.replace('/', ' ');
        console.log(fecha);
        var findate = moment(fecha).add(28, 'm').toDate();
        console.log(findate);
      
    this.EventoManual.idEventos=0;
    this.EventoManual.idUsuario = this.authservice.token.userId;
    this.EventoManual.idConsulta = 0;
    this.EventoManual.titulo = "CONSULTA PRUEBA";
    this.EventoManual.inicio = new Date(fecha);
    this.EventoManual.fin = findate;
    this.EventoManual.ubicacion="";
    this.EventoManual.conferencia = "tipo de conferencia";
    this.EventoManual.descripcion = "tipo de descripcion";
    this.EventoManual.alerta= findate;
    this.EventoManual.visible = true;
    this.EventoManual.tipoEventoId =3;
    this.EventoManual.invitadosViewList=[];
    this.EventoManual._eventoDescripcion= "tipo de _eventoDescripcion"
    this.EventoManual._eventoColor= "#00ff00";


 
    
    
    console.log(this.EventoManual);

  

    
    this.closeModal(true);
        
    }


    private SetHoursAgenda(FECHA,EMAT,SMAT,EVES,SVES,ENOC,SNOC){
  
        // console.log();
        // console.log(this.Agenda.domSalMat);
        console.log(FECHA);
        console.log(EMAT);
        console.log(SMAT);
        console.log(EVES);
        console.log(SVES);
        console.log(ENOC);
        console.log(SNOC);
        console.log("fin parametros");
        
        this.rangosHorasM=[];
        this.rangosHorasV=[];
        this.rangosHorasN=[];
        var INIM = moment(EMAT, 'H:mm:ss');
        var ENDM = moment(SMAT, 'H:mm:ss');
        var INIV = moment(EVES, 'H:mm:ss');
        var ENDV = moment(SVES, 'H:mm:ss');
        var ININ = moment(ENOC, 'H:mm:ss');
        var ENDN = moment(SNOC, 'H:mm:ss');
          
        console.log(INIM);
        console.log(ENDM);
        console.log(INIV);
        console.log(ENDV);
        console.log(ININ);
        console.log(ENDN);
        console.log("fin MOMENTos");

      
         //MATUTINA       
    for(var hourM = moment(INIM, 'HH:mm:ss'); hourM.isBefore(ENDM); hourM.add(30, 'minutes') ){
      
      var horsM="";
      
      var hooorsM =hourM.hour().toString();
      if(hooorsM.length==1){
        horsM ="0"+hourM.hour();
      }else{
        horsM =hourM.hour().toString();
      }

      if(hourM.minute().toString()=="0"){
        this.rangosHorasM.push({hora : horsM+":00", activo:true});
      }else{
        this.rangosHorasM.push({hora : horsM+":"+hourM.minute(), activo:true});

      }
    }
    console.log("fin MATUTINA"); 
    //VESPERTINA
    for(var hourVES = moment(INIV); hourVES.isBefore(ENDV); hourVES.add(30, 'minutes') ){

      var horsV="";      
      var hooorsV =hourVES.hour().toString();
      if(hooorsV.length==1){
        horsV ="0"+hourVES.hour();
      }else{
        horsV =hourVES.hour().toString();
      }

      if(hourVES.minute().toString()=="0"){
        this.rangosHorasV.push({hora : horsV+":00", activo:true});
      }else{
        this.rangosHorasV.push({hora : horsV+":"+hourVES.minute(), activo:true});
      }
    }
    console.log("fin VESPERTINA");

    //NOCTURNA
    for(var hourNOCTURNA = moment(ININ); hourNOCTURNA.isBefore(ENDN); hourNOCTURNA.add(30, 'minutes') ){
      var horsN="";
      var hooorsN =hourNOCTURNA.hour().toString();
      if(hooorsN.length==1){
        horsN ="0"+hourNOCTURNA.hour();
      }else{
        horsN =hourNOCTURNA.hour().toString();
      }
      if(hourNOCTURNA.minute().toString()=="0"){
        this.rangosHorasN.push({hora : horsN+":00", activo:true});
      }else{
        this.rangosHorasN.push({hora : horsN+":"+hourNOCTURNA.minute(), activo:true});
      }
    }
    console.log("fin NOCTURNA");

        console.log(this.rangosHorasM);
        console.log(this.rangosHorasV);
        console.log(this.rangosHorasN);

        console.log("FIN AGENDA");



        var iduser = this.authservice.token.userId;
        var fecevento = this.FERCHA+' '+'00:00:00'
        var start  =moment(fecevento).toDate();
        var end  = moment(fecevento).add(23, 'hours').add(59, 'minutes').add(59, 'seconds').toDate();
     
    
        var urls= "/eventos-auth/search?idUsuario="+iduser+"&idTipoEvento=2,3,4&size=10000000&startDate="+start.getTime()+"&endDate="+end.getTime();
        console.log(urls);

    this.eventosService.getEventos(urls).subscribe((eventos)=>{
     console.log(eventos);


      
     for (let e = 0;e < eventos.content.length; e++) {

        console.log(eventos.content[e]["inicio"]);
        var das = new Date(eventos.content[e]["inicio"]);
        console.log(das);
        var start = moment(das, 'H:mm:ss');

        console.log(start.hours().toString());
        console.log(start.minutes().toString());
        var horario="";
        var horasss = "";
        if(start.hours().toString().length==1){
          horasss ="0"+start.hours().toString()
        }else{
          horasss =start.hours().toString()
        }
        if(start.minutes().toString()=="0"){
          horario = horasss+":00";
        }else{
          horario = horasss+":"+start.minutes().toString();
        }
        //matutino
        for (let mat = 0; mat < this.rangosHorasM.length; mat++) {
          if(horario.trim()==this.rangosHorasM[mat]["hora"].trim()){
            this.rangosHorasM[mat]["activo"]=false;
          }
          
        }
        //vespertino

        for (let ves = 0; ves < this.rangosHorasV.length; ves++) {
            if(horario.trim()==this.rangosHorasV[ves]["hora"].trim()){
              this.rangosHorasV[ves]["activo"]=false;
            }
            
          }
        //NOCTURNO
        for (let noc = 0; noc < this.rangosHorasN.length; noc++) {
          if(horario.trim()==this.rangosHorasN[noc]["hora"].trim()){
            this.rangosHorasN[noc]["activo"]=false;
          }
            
          }
        console.log("fin for de busqueda");
      }

    });



    }
    
    private get calendar(): Calendar {
        if (!this._calendar && this.calendarComponent) {
            this._calendar = this.calendarComponent.getApi();
        }
        return this._calendar;
    }

    private get datepickerSelector(): any {
        return $(this.datepickerEl.nativeElement);
    }

    private get modalAvailability(): any {
        if (this.modalAvailabilityEl) {
            return $(this.modalAvailabilityEl.nativeElement);
        }

        return null;
    }

    onSelectEspecialidad($event) {
        $event = $event || {nombre: ''};
        this.pageSearch.name = $event.nombre;
        this.searchData(true);
    }

    onSelectMEDICO(){
        console.log(this.medicoSelecteds)
        
        this.doctorService.getMedAgenda(this.medicoSelecteds).subscribe(
            (Agenda) => {
             if(Agenda.content.length>0){
                 this.Agenda = new Agendamedicos();
                 this.Agenda = Agenda.content[0];
             }
            }
        ); 
    }

    searchData(reset = false, goto = 0) {
        if (reset) {
            this.paginadoMedico.number = 0;
            this.paginadoMedico.first = !0;
        }

        if (this.paginadoMedico.number > 0 && goto < 0) {
            this.pageSearch.page = this.paginadoMedico.number - 1;
        }

        if (this.paginadoMedico.number < this.paginadoMedico.totalPages && goto > 0) {
            this.pageSearch.page = this.paginadoMedico.number + 1;
        }

       // this.isLoadingData = true;
        if(this.authservice.token.idTipoUsuario!=10000){
        this.pageSearch.selectGroup=Number( localStorage.getItem("id_select_group"));
        }
        this.doctorService.getPage(this.pageSearch).subscribe(pageMedicoView => {
          //  this.isLoadingData = false;
            this.paginadoMedico = pageMedicoView;
        });
    }

    closeModal(saveDate: boolean): void {
        if (this.addedEvent || this.addedEvent == undefined) { // GGR20200519 ver por qué addedEvent es undefined
            if (this.modalAvailability) {
                this.modalAvailability.modal('hide');
                if (!saveDate) {
                    if (this.previouslySaved.reopen) {
                        this.addedEvent.setDates(this.previouslySaved.event.start, this.previouslySaved.event.end);
                    } else {
                        this.addedEvent && this.addedEvent.remove();
                        delete this.addedEvent;
                    }
                } else if (this.addedEvent) {
                    this.previouslySaved.event = this.calendar.getEventById(this.addedEvent.id);
                }
                this.close.emit(this.EventoManual);
            }
        }
    }

    private putTemporalEvent($event) {
        delete this.addedEvent;
        this.addedEvent = this.calendar.addEvent($event);
    }

    private disableDatesBeforeNow: Function = (current: Date): boolean => {
        return current.getTime() + 60000 < new Date().getTime();
    };

    private noOverlapingEvents: Function = (newEventDates: { startDate: Date, endDate: Date }): boolean => {
        if (this.agendaEvents && this.agendaEvents.content.length) {
            return !this.agendaEvents.content
                .some((event) => {
                    return !event.fin ||
                        (newEventDates.startDate < event.fin && newEventDates.endDate > event.inicio);
                })
        }

        return true;
    };

    private applyFilterAndRequest(params?: { startDate?: Date, endDate?: Date }): void {
        if (this.medicoSeleccionado && this.medicoSeleccionado.idUsuario) {
            if (!this.filtersRequestEvents.idUsuario) {
                this.filtersRequestEvents.idUsuario = this.medicoSeleccionado.idUsuario;
                this.filtersRequestEvents.idTipoEvento = [2];
                this.filtersRequestEvents.size = 50;
            }

            if (!params) {
                params = {
                    startDate: new Date(),
                    endDate: new Date()
                };
                params.startDate.setHours(0, 0, 0, 0);
                params.endDate.setHours(23, 59, 59, 999);
            } else {
                if (!params.endDate) {
                    params.endDate = new Date(params.startDate);
                    params.endDate.setHours(23, 59, 59, 999);
                }
            }

            this.filtersRequestEvents.startDate = params.startDate.getTime();
            this.filtersRequestEvents.endDate = params.endDate.getTime();

            this.getAgendaEvent();
        } else {
            if (this.authservice.token && this.authservice.token.userId) {
                if (this.contadorIntentos < 2) {
                    this.contadorIntentos++;
                    this.doctorService.getByIdUser(this.authservice.token.userId)
                        .subscribe((medico) => {
                            this.medicoSeleccionado = JSON.parse(JSON.stringify(medico));
                            this.applyFilterAndRequest();
                        });
                }
            } // Aqui si no se que deba suceder ¿Intentar recuperar el token?
        }
    }

    /**
     * Al cambiar la vista
     * @param event
     */
    datesRender(event): void {
        if (!this.datepickerConfig.clicked && event.view.activeStart) {
            this.datepickerSelector.datepicker('setDate', event.view.activeStart);
        }
        this.applyFilterAndRequest({startDate: event.view.activeStart, endDate: event.view.activeEnd});

        this.datepickerConfig.clicked = false;
    }

    /**
     * Click en alguna celda vacía en la agenda
     * @param $event {allDay: boolean, date: Date, dateStr: string, dayEl: HTMLElement, jsEvent: MouseEvent, view: DatGridView}
     */
    dateClick($event: any) {
        this.editingEvent = false;
        if (!this.disableDatesBeforeNow($event.date)) {
            let expectedEndDate = new Date($event.date);
            expectedEndDate.setMinutes(expectedEndDate.getMinutes() + 30);
            if (this.noOverlapingEvents({startDate: $event.date, endDate: expectedEndDate})) {
                this.allDayEvent = $event.allDay || !0;
                this.modelAddEvent.inicio = $event.date;
                this.modelAddEvent.fin = expectedEndDate;
                this.addedEvent && this.addedEvent.remove();
                $event.end = expectedEndDate;
                this.putTemporalEvent($event);
            }
        } else {
            this.notificationService.smallBoxError({content: 'No se puede cambiar el evento a dias antes de hoy '})
        }
    }

    eventEdited(event): void {
        const eventApi: EventApi = event.event;
        if (eventApi.start < new Date()) {
            event.revert();
            this.notificationService.smallBox({
                content: `No se puede cambiar el evento a dias antes de hoy`,
                color: '#5D5D5D',
                timeout: 4000
            });
        } else {
            Object.assign(this.modelAddEvent, <Evento>event.event.extendedProps);
            this.modelAddEvent.inicio = eventApi.start;
            this.modelAddEvent.fin = eventApi.end || this.modelAddEvent.fin;
            const evento = Object.assign({}, this.modelAddEvent);
            // @ts-ignore
            evento.inicio = this.modelAddEvent.inicio.getTime();
            // @ts-ignore
            evento.fin = isDate(this.modelAddEvent.fin) && this.modelAddEvent.fin.getTime() || this.modelAddEvent.fin;

            // @ts-ignore
            evento.fin = (evento.fin <= evento.inicio ? ((new Date(eventApi.start.getTime()).setSeconds(eventApi.start.getSeconds() + 1))) : evento.fin);

            if (this.modelAddEvent.alerta) {
                // @ts-ignore
                evento.alerta = isDate(this.modelAddEvent.alerta) && this.modelAddEvent.alerta.getTime() || this.modelAddEvent.alerta;
            }

            if ([4, 3].includes(evento.tipoEventoId)) {
                evento.alerta = evento.inicio;
            }

            this.addedEvent = eventApi;
        }
    }

    private getAgendaEvent(): void {
        this.eventosService.getPage(this.filtersRequestEvents).subscribe((pageEvento) => {
            this.agendaEvents = pageEvento;
            if (typeof this.calendar === 'undefined') {
                const intervalWaitToCalendar: number = window.setInterval(() => {
                    if (typeof this.calendar === 'undefined') {
                        return;
                    }
                    clearInterval(intervalWaitToCalendar);
                    this.agendaEvents.content.forEach((agendaEvent) => {
                        this.addEventToCalendar(agendaEvent);
                    });
                }, 1000);

                } else {
                    this.calendar.removeAllEvents();
                    this.agendaEvents.content.forEach((agendaEvent) => this.addEventToCalendar(agendaEvent));
                    if (this.addedEvent && this.addedEvent.start <= this.filtersRequestEvents.endDate && this.addedEvent.end >= this.filtersRequestEvents.startDate) {
                        this.addedEvent = this.calendar.addEvent({
                            id: this.addedEvent.id,
                            allDay: this.addedEvent.allDay,
                            start: this.addedEvent.start,
                            end: this.addedEvent.end ? this.addedEvent.end : null,
                            title: this.addedEvent.title,
                            classNames: this.addedEvent.classNames,
                            editable: true,
                            extendedProps: this.addedEvent.extendedProps,
                            backgroundColor: this.addedEvent.backgroundColor,
                            overlap: false
                        });
                    }
                }
            });
    }

    private addEventToCalendar(agendaEvent: Evento): void {
        if (agendaEvent) {
            if (!agendaEvent.fin) {
                agendaEvent.inicio.setHours(0, 0, 0, 0);
            }
            this.calendar.addEvent({
                id: agendaEvent.idEventos,
                allDay: !agendaEvent.fin,
                start: agendaEvent.inicio,
                end: agendaEvent.fin ? agendaEvent.fin : null,
                title: agendaEvent.titulo,
                classNames: ['consulta', agendaEvent._eventoDescripcion.toLowerCase()],
                editable: false,
                extendedProps: agendaEvent,
                backgroundColor: this.hexToRGB(agendaEvent._eventoColor, .3)
            });
        }
    }

    private hexToRGB(hex, alpha) {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return `rgba(${r}, ${g}, ${b}, ${alpha})`;
        } else {
            return `rgba(${r}, ${g}, ${b})`;
        }
    }
}
