import {Routes, RouterModule} from '@angular/router';
import {MainLayoutComponent} from './shared/layout/app-layouts/main-layout.component';
import {ModuleWithProviders} from '@angular/core';
import {loginNioComponent} from './+login-nio/login-nio.component';
import {TokenpacComponent} from './+token/tokenpac.component';
import {CronComponent} from './cron/cron.component';
import {IframeComponent} from './+iFrame/iframe.component';
import {CanAccessService} from './shared/canaccess/canAccess.service';
import { IframeConsultaComponent } from './+iFrameConsulta/iframe-consulta.component';


export const routes: Routes = [
    {
        path: 'login',
        component: loginNioComponent
    },
    {
        path: 'TOKEN',
        component: TokenpacComponent
    },
    {
        path: 'CRON',
        component: CronComponent
    },
    {
        path: 'iframe',
        component: IframeComponent,
        canActivate: [CanAccessService]
    },{
        path: 'iframeConsulta/:id',
        component: IframeConsultaComponent,
        canActivate: [CanAccessService]
    },
   
    {
        path: '', 
        component: MainLayoutComponent,
        canActivate: [CanAccessService],
        data: {pageTitle: 'Home'},
        children: [
            {
                path: '', redirectTo: 'home', pathMatch: 'full',
            },
            
            {
                path: 'home',
                loadChildren: 'app/+dashboard/+general-dash/general-dashboard.module#GeneralDashboardModule',
                data: {pageTitle: 'Home'}
            },
            {
                path: 'consulta',
                loadChildren: 'app/+consulta/consulta.module#ConsultaModule',
                canActivate: [CanAccessService]
            },
            {
                path: 'paciente',
                loadChildren: 'app/+paciente/paciente.module#PacienteModule',
            },
            {
                path: 'puebla',
                loadChildren: 'app/expediente-puebla/expediente-puebla.module#ExpedientePueblaModule',
                canActivate: [CanAccessService]
            },
            {
                path: 'doctor',
                loadChildren: 'app/+doctor/doctor.module#DoctorModule',
                canActivate: [CanAccessService]
            },
            {
                path: 'canalizar',
                loadChildren: 'app/+canalizar/canalizar.module#canalizarModule'
            },
            {
                path: 'cuestionarios',
                loadChildren: 'app/+cuestionarios/cuestionarios.module#CuestionariosModule'
            },
            {
                path: 'notificaciones',
                loadChildren: 'app/+notificaciones/notificaciones.module#NotificacionesModule'
            },
            {
                path: 'agenda',
                loadChildren: 'app/+agenda/agenda.module#AgendaModule',
                canActivate: [CanAccessService]
            },
            {
                path: 'administracion',
                loadChildren: 'app/+admin/admin.module#AdminModule'
            },
            {
                path: 'formatos',
                loadChildren: 'app/+formato/formato.module#FormatoModule',
                canActivate: [CanAccessService]
            },
            {
                path: 'estudios',
                loadChildren: 'app/+admin/catalogos/estudios/estudios.module#EstudiosModule',
                canActivate: [CanAccessService]
            },
            {
                path: 'turnosLaborales',
                loadChildren: 'app/+turnosLaborales/turnos.module#TurnosModule',
                canActivate: [CanAccessService]
            },
           
           
        ]
    },

    {path: '**', redirectTo: 'login'}
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {useHash: true});
