import {NgModule, ApplicationRef, LOCALE_ID} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpModule, RequestOptions, XHRBackend} from '@angular/http';
import { UserIdleModule } from 'angular-user-idle';
import {FormsModule} from '@angular/forms';

/*
 * Platform and Environment providers/directives/pipes
 */
import {routing} from './app.routing'
// App is our top level component
import {AppComponent} from './app.component';
import {APP_RESOLVER_PROVIDERS} from './app.resolver';
import {AppState, InternalStateType} from './app.service';

// Core providers
import {CoreModule} from './core/core.module';
import {NiomedicLayoutModule} from './shared/layout';
import {AuthNioService} from './+login-nio/auth-nio.service';
import {CustomHttp} from 'app/common/http/custom.http';
import {NotificationService} from './shared/utils/notification.service';
import {UserDetailsService} from './shared/user/user.details.service';
/* register language packagess */
import {registerLocaleData} from '@angular/common';
import es from '@angular/common/locales/es';
import {CiesService} from './shared/services/cies.service';

registerLocaleData(es);
import {NZ_I18N, es_ES} from 'ng-zorro-antd';
import {CanAccessService} from './shared/canaccess/canAccess.service';
import {HasPermissionService} from './shared/haspermission/hasPermission.service';
import {FormioAppConfig, FormioModule} from "angular-formio";
import {AppConfig} from "./shared/formio-config";
import {PacienteService, PacienteServicesModule} from "./+paciente/paciente.service";
import { TokenpacModule } from './+token/tokenpac.module';
import { CronComponent } from './cron/cron.component';
import { CronModule } from './cron/cron.module';
import { IframeModule } from './+iFrame/iframe.module';
import { IframeConsultaModule } from './+iFrameConsulta/iframe-consulta.module';

import { StudiesService } from './shared/services/studies.service';
import { PRIVACIDADComponent } from './+footer/privacidad/privacidad.component';
import { POLITICASComponent } from './+footer/politicas/politicas.component';
import { CONTACTOComponent } from './+footer/contacto/contacto.component';
//import { IframeConsultaComponent } from './+iFrameConsulta/iframe-consulta/iframe-consulta.component';
//import { SendEmailComponent } from './send-email/send-email.component';

// Application wide providers
const APP_PROVIDERS = [
    ...APP_RESOLVER_PROVIDERS,
    AppState,
    UserDetailsService,
    CanAccessService,
    HasPermissionService,
    CiesService,
    StudiesService,
    AuthNioService,
    PacienteService
];

type StoreType = {
    state: InternalStateType,
    restoreInputValues: () => void,
    disposeOldHosts: () => void
};
 
/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent, PRIVACIDADComponent, POLITICASComponent, CONTACTOComponent],
    imports: [ // import Angular's modules
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpModule,
        CoreModule,
        NiomedicLayoutModule,
        routing,
        FormioModule,
        UserIdleModule.forRoot({idle: 1800, timeout: 1800, ping: 600}),
        PacienteServicesModule.forRoot(),
        TokenpacModule,CronModule,IframeModule,IframeConsultaModule
    ],
    exports: [],
    providers: [ // expose our Services and Providers into Angular's dependency injection Rh&2lHIAtWlE
        APP_PROVIDERS,
        {
            provide: CustomHttp,
            useFactory: httpCustomFactory,
            deps: [XHRBackend, RequestOptions, AuthNioService, NotificationService]
        },
        {provide: NZ_I18N, useValue: es_ES},
        {provide: LOCALE_ID, useValue: 'es'},
        {provide: FormioAppConfig, useValue: AppConfig}
    ]
})
export class AppModule {
    constructor(public appRef: ApplicationRef, public appState: AppState) {
    }
}

export function httpCustomFactory(backend: XHRBackend, defaultOptions: RequestOptions, auth: AuthNioService, notification: NotificationService) {
    return new CustomHttp(backend, defaultOptions, auth, notification);
}
